import { defineStore } from "pinia";
import {
  Xero,
  XeroAccount,
  XeroAssociatedLocation,
  LatestXeroInvoice,
  ExclusionRule,
  ScheduleTemplateManage,
  ScheduleTemplate,
} from "~/types/xero";
import { Option } from "~/types/general";
import { ToastType } from "~/types/general";

import { useRouter } from "vue-router";

export const useXeroStore = defineStore("xero", () => {
  // Xero connection
  const connection = ref<Xero>({} as Xero);
  const redirect_url = ref("");

  function auth() {
    const router = useRouter();

    return useVaniloApi(`/xero/auth/authorize`)
      .then((response: any) => {
        if (response.redirect_url) {
          const link = document.createElement("a");
          link.href = response.redirect_url;
          link.setAttribute("target", "_self");
          link.click();
        }

        redirect_url.value = response.redirect_url;

        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function logoutXero() {
    const router = useRouter();

    return useVaniloApi(`/xero/disconnect`)
      .then((response: any) => response)
      .catch((error) => {
        useToast(error.toString(), {
          type: ToastType.ERROR,
          duration: 3000,
        });
        console.log(error);
      });
  }

  // Organizations
  const organizationsList = ref<Option[]>([]);
  const organizationsEditing = ref<any[]>([]);

  function getOrganizations() {
    return useVaniloApi(`/xero`)
      .then((response: any) => {
        if (response) {
          connection.value = response;

          organizationsList.value = response.tenants?.map((i) => ({
            name: i.Name,
            value: i.Id,
          }));

          organizationsEditing.value = response.tenants?.map((i) => ({
            tenant_id: i.Id,
            location_id: i.location_id ?? null,
            ...(i.brand_id && { brand_id: i.brand_id ?? null }),
            invoices: {
              account: i.invoices?.account ?? null,
              integration_settings: i.invoices?.integration_settings ?? null,
              account_mapping: i.invoices?.account_mapping ?? null,
            },
            bills: {
              account: i.bills?.account ?? null,
              integration_settings: i.bills?.integration_settings ?? null,
              account_mapping: i.bills?.account_mapping ?? null,
            },
          }));
        }

        return response;
      })
      .catch((error) => {
        console.log(error);
        useToast(error.toString(), {
          type: ToastType.ERROR,
          duration: 3000,
        });
      });
  }

  // Accounts
  const accounts = ref<XeroAccount[]>([]);
  const sales = ref<Option[]>([]);

  function getAccounts() {
    return useVaniloApi(`/xero/get-accounts`)
      .then((response: any) => {
        if (response) {
          accounts.value = response;
          sales.value = response.map((i) => ({
            name: `${i.code} - ${i.name} ${i.type}`,
            value: i.id,
          }));
        }
        return response;
      })
      .catch((error) => {
        console.log(error);
        useToast(error.toString(), {
          type: ToastType.ERROR,
          duration: 3000,
        });
      });
  }

  // Locations
  const editingLocationXeroAssociatedLocation = ref<XeroAssociatedLocation>(
    {} as XeroAssociatedLocation
  );

  function associateLocations() {
    const { bills, invoices, ...other } =
      editingLocationXeroAssociatedLocation.value;

    organizationsEditing.value = organizationsEditing.value.filter(
      (i) =>
        i.location_id &&
        i.location_id !==
          editingLocationXeroAssociatedLocation.value.location_id
    );

    organizationsEditing.value.push({
      ...other,
      invoices: {
        account: {
          ...(invoices.account.sequence_number && {
            sequence_number: invoices.account.sequence_number,
          }),
          ...(invoices.account.invoice_prefix && {
            invoice_prefix: invoices.account.invoice_prefix,
          }),
          draft_status: invoices.account.draft_status,
          inventory_item: invoices.account.inventory_item,
          order_with_zero_totals: invoices.account.order_with_zero_totals,
          use_alphabetical_order: invoices.account.use_alphabetical_order,
        },
        ...(Object.values(invoices.integration_settings).some(
          (i) => i.length > 0
        ) && {
          integration_settings: {
            ...(invoices.integration_settings.invoices?.length && {
              invoices: invoices.integration_settings.invoices,
            }),
            ...(invoices.integration_settings.payments?.length && {
              payments: invoices.integration_settings.payments,
            }),
            ...(invoices.integration_settings.fees?.length && {
              fees: invoices.integration_settings.fees,
            }),
            ...(invoices.integration_settings.shipping?.length && {
              shipping: invoices.integration_settings.shipping,
            }),
          },
        }),
        ...(Object.values(invoices.account_mapping).some(
          (i) => i.length > 0
        ) && {
          account_mapping: {
            ...(invoices.account_mapping.sales?.length && {
              sales: invoices.account_mapping.sales,
            }),
            ...(invoices.account_mapping.payments?.length && {
              payments: invoices.account_mapping.payments,
            }),
            ...(invoices.account_mapping.fees?.length && {
              fees: invoices.account_mapping.fees,
            }),
            ...(invoices.account_mapping.rounding?.length && {
              rounding: invoices.account_mapping.rounding,
            }),
            ...(invoices.account_mapping.shipping?.length && {
              shipping: invoices.account_mapping.shipping,
            }),
          },
        }),
      },
      bills: {
        account: {
          ...(bills.account.sequence_number && {
            sequence_number: bills.account.sequence_number,
          }),
          ...(bills.account.invoice_prefix && {
            invoice_prefix: bills.account.invoice_prefix,
          }),
          draft_status: bills.account.draft_status,
          inventory_item: bills.account.inventory_item,
          order_with_zero_totals: bills.account.order_with_zero_totals,
          use_alphabetical_order: bills.account.use_alphabetical_order,
        },
        ...(Object.values(bills.integration_settings).some(
          (i) => i.length > 0
        ) && {
          integration_settings: {
            ...(bills.integration_settings.invoices?.length && {
              invoices: bills.integration_settings.invoices,
            }),
            ...(bills.integration_settings.payments?.length && {
              payments: bills.integration_settings.payments,
            }),
            ...(bills.integration_settings.fees?.length && {
              fees: bills.integration_settings.fees,
            }),
            ...(bills.integration_settings.shipping?.length && {
              shipping: bills.integration_settings.shipping,
            }),
          },
        }),
        ...(Object.values(bills.account_mapping).some((i) => i.length > 0) && {
          account_mapping: {
            ...(bills.account_mapping.sales?.length && {
              sales: bills.account_mapping.sales,
            }),
            ...(bills.account_mapping.payments?.length && {
              payments: bills.account_mapping.payments,
            }),
            ...(bills.account_mapping.fees?.length && {
              fees: bills.account_mapping.fees,
            }),
            ...(bills.account_mapping.rounding?.length && {
              rounding: bills.account_mapping.rounding,
            }),
            ...(bills.account_mapping.shipping?.length && {
              shipping: bills.account_mapping.shipping,
            }),
          },
        }),
      },
    });

    const body = {
      organizations: organizationsEditing.value,
    };

    return useVaniloApi(`/xero/associate-locations`, {
      method: "POST",
      body: body,
    })
      .then((response: any) => {
        connection.value.tenants = response?.data.tenants;
        return response;
      })
      .catch((error) => {
        console.log(error);
        useToast(error.toString(), {
          type: ToastType.ERROR,
          duration: 3000,
        });
      });
  }

  // Invoices
  const latestInvoice = ref<LatestXeroInvoice>({} as LatestXeroInvoice);

  function getLatestInvoice() {
    return useVaniloApi(`/xero/latest-invoice/1`)
      .then((response: any) => {
        if (response?.data?.errors?.length) {
          useToast(response?.data.errors, {
            type: ToastType.INFO,
            duration: 3000,
          });
        }
        if (response?.data) {
          latestInvoice.value = response?.data;
        }
        return response;
      })
      .catch((error) => {
        useToast(error.toString(), {
          type: ToastType.ERROR,
          duration: 3000,
        });
        console.log(error);
      });
  }

  function createInvoice(id: number) {
    return useVaniloApi(`/xero/sync-invoice/${id}`, {
      method: "POST",
    })
      .then((response: any) => {
        if (response?.data?.errors?.length) {
          useToast(response?.data.errors, {
            type: ToastType.INFO,
            duration: 3000,
          });
        }
        return response;
      })
      .catch((error) => {
        useToast(error.toString(), {
          type: ToastType.ERROR,
          duration: 3000,
        });
        console.log(error);
      });
  }

  function updateInvoice(id: number) {
    return useVaniloApi(`/xero/update-invoice/${id}`, {
      method: "POST",
    })
      .then((response: any) => {
        if (response?.data?.errors?.length) {
          useToast(response?.data.errors, {
            type: ToastType.INFO,
            duration: 3000,
          });
        }
        return response;
      })
      .catch((error) => {
        useToast(error.toString(), {
          type: ToastType.ERROR,
          duration: 3000,
        });
        console.log(error);
      });
  }

  function deleteInvoice(id: number) {
    return useVaniloApi(`/xero/delete-invoice/${id}`, {
      method: "DELETE",
    })
      .then((response: any) => {
        if (response?.data?.errors?.length) {
          useToast(response?.data.errors, {
            type: ToastType.INFO,
            duration: 3000,
          });
        }
        return response;
      })
      .catch((error) => {
        useToast(error.toString(), {
          type: ToastType.ERROR,
          duration: 3000,
        });
        console.log(error);
      });
  }

  // BILLS
  const latestBill = ref<LatestXeroInvoice>({} as LatestXeroInvoice);

  async function getLatestBill() {
    let response: any;

    try {
      response = await useVaniloApi(`/xero/latest-bill/1`);
    } catch (error) {
      useToast(error.toString(), {
        type: ToastType.ERROR,
        duration: 3000,
      });
      console.log(error);
    }

    if (response?.data?.errors?.length) {
      useToast(response?.data.errors, {
        type: ToastType.INFO,
        duration: 3000,
      });
    }
    if (response?.data) {
      latestBill.value = response?.data;
    }
    return response;
  }

  async function createBill(id) {
    let response: any;

    try {
      response = await useVaniloApi(`/xero/sync-bill/${id}`, {
        method: "POST",
      });
    } catch (error) {
      useToast(error.toString(), {
        type: ToastType.ERROR,
        duration: 3000,
      });
      console.log(error);
    }

    if (response?.data?.errors?.length) {
      useToast(response?.data.errors, {
        type: ToastType.INFO,
        duration: 3000,
      });
    }

    return response;
  }

  async function deleteBill(id) {
    let response: any;

    try {
      response = await useVaniloApi(`/xero/delete-bill/${id}`, {
        method: "DELETE",
      });
    } catch (error) {
      useToast(error.toString(), {
        type: ToastType.ERROR,
        duration: 3000,
      });
      console.log(error);
    }

    if (response) {
      useToast("Bill has been deleted successfully!", {
        type: ToastType.INFO,
        duration: 3000,
      });
    }

    return response;
  }

  //CONTACTS
  async function syncContacts(storeId?) {
    let response: any;

    try {
      response = await useVaniloApi(`/xero/sync-contacts/${storeId ?? "1"}`, {
        method: "POST",
      });
    } catch (error) {
      console.log(error);

      useToast(error.toString(), {
        type: ToastType.ERROR,
        duration: 3000,
      });
    }
    if (response?.message) {
      useToast(response.message, {
        type: ToastType.SUCCESS,
        duration: 3000,
      });
    }

    return response;
  }

  async function syncSingleContact(id, storeId?) {
    let response: any;

    try {
      response = await useVaniloApi(`/xero/sync-contact/${storeId ?? "1"}`, {
        method: "POST",
        body: {
          customer_id: +id,
        },
      });
    } catch (error) {
      console.log(error);

      useToast(error.toString(), {
        type: ToastType.ERROR,
        duration: 3000,
      });
    }
    if (response?.message) {
      useToast(response.message, {
        type: ToastType.SUCCESS,
        duration: 3000,
      });
    }

    return response;
  }

  //EXCLUSION RULES
  const syncExclusionRules = ref<ExclusionRule[]>([]);
  const viewingExclusionRule = ref<ExclusionRule>({} as ExclusionRule);

  async function getExclusionRulesList() {
    let response: any;

    try {
      response = await useVaniloApi(`/xero-conditions`);
    } catch (error) {
      useToast(error.toString(), {
        type: ToastType.ERROR,
        duration: 3000,
      });
      console.log(error);
    }

    if (response?.data?.errors?.length) {
      useToast(response?.data.errors, {
        type: ToastType.INFO,
        duration: 3000,
      });
    }
    if (response?.data) {
      syncExclusionRules.value = response?.data;
    }
    return response;
  }

  async function getExclusionRule(id) {
    let response: any;

    try {
      response = await useVaniloApi(`/xero-conditions/${id}`);
    } catch (error) {
      useToast(error.toString(), {
        type: ToastType.ERROR,
        duration: 3000,
      });
      console.log(error);
    }

    if (response?.data?.errors?.length) {
      useToast(response?.data.errors, {
        type: ToastType.INFO,
        duration: 3000,
      });
    }
    if (response?.data) {
      viewingExclusionRule.value = response?.data;
    }
    return response;
  }

  async function createExclusionRule(payload) {
    let response: any;

    try {
      response = await useVaniloApi(`/xero-conditions`, {
        method: "POST",
        body: payload,
      });
    } catch (error) {
      useToast(error.toString(), {
        type: ToastType.ERROR,
        duration: 3000,
      });
      console.log(error);
    }

    if (response?.data?.errors?.length) {
      useToast(response?.data.errors, {
        type: ToastType.INFO,
        duration: 3000,
      });
    }

    return response;
  }

  async function updateExclusionRule(id, payload) {
    let response: any;

    try {
      response = await useVaniloApi(`/xero-conditions/${id}`, {
        method: "POST",
        body: payload,
      });
    } catch (error) {
      useToast(error.toString(), {
        type: ToastType.ERROR,
        duration: 3000,
      });
      console.log(error);
    }

    if (response?.data?.errors?.length) {
      useToast(response?.data.errors, {
        type: ToastType.INFO,
        duration: 3000,
      });
    }

    return response;
  }

  async function deleteExclusionRule(id) {
    let response: any;

    try {
      response = await useVaniloApi(`/xero-conditions/${id}`, {
        method: "DELETE",
      });
    } catch (error) {
      useToast(error.toString(), {
        type: ToastType.ERROR,
        duration: 3000,
      });
      console.log(error);
    }

    if (response?.data?.errors?.length) {
      useToast(response?.data.errors, {
        type: ToastType.INFO,
        duration: 3000,
      });
    }

    return response;
  }

  // Schedule Templates

  const scheduleTemplateList = ref<ScheduleTemplate[]>(
    [] as ScheduleTemplate[]
  );
  const viewingScheduleTemplate = ref<ScheduleTemplate>({} as ScheduleTemplate);
  const editingScheduleTemplate = ref<ScheduleTemplateManage>(
    {} as ScheduleTemplateManage
  );

  //SCHEDULE TEMPLATES
  async function getScheduleTemplateList() {
    let response: any;

    try {
      response = await useVaniloApi(`/xero-schedule-templates`);
    } catch (error) {
      useToast(error.toString(), {
        type: ToastType.ERROR,
        duration: 3000,
      });
      console.log(error);
    }

    if (response?.data?.errors?.length) {
      useToast(response?.data.errors, {
        type: ToastType.INFO,
        duration: 3000,
      });
    }
    if (response?.data) {
      scheduleTemplateList.value = response?.data;
    }
    return response;
  }

  async function getScheduleTemplate(id) {
    let response: any;

    try {
      response = await useVaniloApi(`/xero-schedule-templates/${id}`);
    } catch (error) {
      useToast(error.toString(), {
        type: ToastType.ERROR,
        duration: 3000,
      });
      console.log(error);
    }

    if (response?.data?.errors?.length) {
      useToast(response?.data.errors, {
        type: ToastType.INFO,
        duration: 3000,
      });
    }
    if (response?.data) {
      viewingScheduleTemplate.value = response?.data;
    }
    return response;
  }

  async function createScheduleTemplate(payload) {
    let response: any;

    try {
      response = await useVaniloApi(`/xero-schedule-templates`, {
        method: "POST",
        body: payload,
      });
    } catch (error) {
      useToast(error.toString(), {
        type: ToastType.ERROR,
        duration: 3000,
      });
      console.log(error);
    }

    if (response?.data?.errors?.length) {
      useToast(response?.data.errors, {
        type: ToastType.INFO,
        duration: 3000,
      });
    }

    return response;
  }

  async function updateScheduleTemplate(id, payload) {
    let response: any;

    try {
      response = await useVaniloApi(`/xero-schedule-templates/${id}`, {
        method: "POST",
        body: payload,
      });
    } catch (error) {
      useToast(error.toString(), {
        type: ToastType.ERROR,
        duration: 3000,
      });
      console.log(error);
    }

    if (response?.data?.errors?.length) {
      useToast(response?.data.errors, {
        type: ToastType.INFO,
        duration: 3000,
      });
    }

    return response;
  }

  async function deleteScheduleTemplate(id) {
    let response: any;

    try {
      response = await useVaniloApi(`/xero-schedule-templates/${id}`, {
        method: "DELETE",
      });
    } catch (error) {
      useToast(error.toString(), {
        type: ToastType.ERROR,
        duration: 3000,
      });
      console.log(error);
    }

    if (response?.data?.errors?.length) {
      useToast(response?.data.errors, {
        type: ToastType.INFO,
        duration: 3000,
      });
    }

    return response;
  }

  return {
    connection,
    redirect_url,
    auth,
    logoutXero,
    organizationsList,
    organizationsEditing,
    getOrganizations,
    accounts,
    sales,
    getAccounts,
    editingLocationXeroAssociatedLocation,
    associateLocations,
    latestInvoice,
    getLatestInvoice,
    createInvoice,
    updateInvoice,

    // Exclusion
    syncExclusionRules,
    viewingExclusionRule,
    getExclusionRulesList,
    getExclusionRule,
    createExclusionRule,
    updateExclusionRule,
    deleteExclusionRule,

    // schedule
    scheduleTemplateList,
    viewingScheduleTemplate,
    editingScheduleTemplate,
    getScheduleTemplateList,
    getScheduleTemplate,
    createScheduleTemplate,
    updateScheduleTemplate,
    deleteScheduleTemplate,

    //
    deleteInvoice,
    getLatestBill,
    latestBill,
    createBill,
    deleteBill,
    syncContacts,
    syncSingleContact,
  };
});
